import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { UtilService } from '../../services/util/util.service'


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';



@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(
    private utilService: UtilService
  ) { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + " " + this.utilService.yyyyMMddHHmmSS() + EXCEL_EXTENSION);
  }

  

  excelToJson(data){
    let workbook = XLSX.read(data,{type:'binary'})
    /* grab first sheet */
    const wsname: string = workbook.SheetNames[0];
    const ws: XLSX.WorkSheet = workbook.Sheets[wsname];

    /* save data */
    let json:string[][] = (XLSX.utils.sheet_to_json(ws, {header: 1}));
    
    if(json.length == 0){
      return json
    }
    let headers = json.shift()
    let arr = []
    json.forEach(e=>{
      if (e.length !== 0) {
        let obj = {}
        headers.forEach((x,i)=>{
          obj[x] = e[i]
        })
        arr.push(obj)  
      }
    })
    return arr
  }

  generateDateTime() {
    let addZero = function (i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    let timestamp = Date.now()
    let year = new Date(timestamp).getFullYear()
    let month = new Date(timestamp).getMonth() + 1 < 10 ? '0' + (new Date(timestamp).getMonth() + 1) : new Date(timestamp).getMonth() + 1
    let day = new Date(timestamp).getDate() < 10 ? '0' + new Date(timestamp).getDate() : new Date(timestamp).getDate()
    let hour = addZero(new Date(timestamp).getHours());
    let minute = addZero(new Date(timestamp).getMinutes());
    let second = addZero(new Date(timestamp).getSeconds());

    let newDate = year.toString() + month + day + " " + hour + minute + second
    return newDate
  }

  

}
