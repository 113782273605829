import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service'
import { LocalService } from '../local/local.service'

import { environment } from "../../../environments/environment"


import * as firebase from 'firebase/app';
//import { window } from 'rxjs-compat/operator/window';



@Injectable({
  providedIn: 'root'
})
export class HttpService {
  currentUser
  apiServer
  host

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private localService: LocalService
  ) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.currentUser = user;
        this.host = window.location.hostname
        // console.log(this.host)
        if (this.host === 'excelcomms-panel.firebaseapp.com' || this.host === 'excelcomms-panel.web.app' || this.host === 'iwo.excelcomms.com.my') {
          this.apiServer = 'https://excelcomms.pickafoo.com/api/'
        }
        else {
          this.apiServer = 'https://excelcomms.pickafoo.com/api/'
        }
        // console.log(this.apiServer)

        console.log('http services loaded');
        //this.getData()
      }
      else {
        return false;
      }
    });
  }

  async createUser(user) {
    const token = await this.authService.getCurrentUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic serverPassword711',
        'uid': this.currentUser.uid,
        'env': environment.production? 'prod':'ote'
      })
    };
    let data = user
    data['idToken'] = token
    console.log(data)
    return this.http.post(this.apiServer + 'createUser', data, httpOptions).toPromise();
  }

  

  async sendEmail(email) {
    const token = await this.authService.getCurrentUserToken();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic serverPassword711',
        'uid': this.currentUser.uid,
        'env': environment.production? 'prod':'ote'
      })
    };
    let data = email
    data['idToken'] = token
    return this.http.post(this.apiServer + 'sendEmail', data, httpOptions).toPromise();

  }

  async updateUserPassword(userId, password) {
    const token = await this.authService.getCurrentUserToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic serverPassword711',
        'uid': this.currentUser.uid,
        'env': environment.production? 'prod':'ote'
      })
    };
    let data = {
      userId: userId,
      password: password
    }

    data['idToken'] = token

    return this.http.post(this.apiServer + 'updateUserPassword', data, httpOptions).toPromise();
  }


  hello() {
    console.log("hello https service")
  }



  async initializeCronJob() {
    const token = await this.authService.getCurrentUserToken();
    console.log(token)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic serverPassword711',
        'uid': this.currentUser.uid,
        'env': environment.production? 'prod':'ote'
      })
    };

    let data = {}
    data['idToken'] = token

    return this.http.post(this.apiServer + 'initializeCronJob', data, httpOptions).toPromise();
  }

  async getData() {
    try {
      const data = await this.initializeCronJob();
      this.localService.setCronExecuteDate()
      console.log(data)
    }
    catch (error) {
      console.log(error)
    }

  }

  cloudflare() {
    return this.http.get('https://www.cloudflare.com/cdn-cgi/trace',{responseType:"text"});
  }



}
