import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';

import * as firebase from 'firebase/app';

import { Client } from '../../model/client.model'


@Injectable({
  providedIn: 'root'
})
export class ClientService {
  currentUser;
  clientRef:firebase.database.Reference;
  clientList = []

  constructor(private http: Http) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.currentUser = user;
        this.clientRef = firebase.database().ref(`/client`);
        console.log('client services loaded');
        this.getClients().once("value",snap=>{
          snap.forEach(c=>{
            this.clientList.push({...c.val(),key:c.key})
          })
        })
      }
    });
   }

   checkLoginStatus(){
     return firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.currentUser = user;
        this.clientRef = firebase.database().ref(`/client`);
        console.log('client services loaded');
      }
    });
   }

  getClients() {
    return this.clientRef;
  }

  getClientById(clientId) {
    return this.clientRef.child(clientId);
  }

  searchClientByName(name) {
    return this.clientRef.orderByChild(name);
  }

  createClient(client){
    client['dtcreate'] = Date.now();
    client['dtmodify'] = Date.now();
    delete client['isNew'];
    delete client['isReturnFromCustomer'];
    return this.clientRef.push(client);
  }

  updateClient(client,clientId){
    client['dtmodify'] = Date.now();
    return this.clientRef.child(clientId).update(client);
  }

}
