import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';


import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import { LoggerService } from '../../services/logger/logger.service';
// import { UserService } from '../../services/user/user.service';

import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'complete-signup.component.html',
  styleUrls: ['./complete-signup.component.scss'],

})
export class CompleteSignupComponent implements OnInit {
  form = {
    email: '',
    password: '',
    invitationId:''

  };
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private location: Location,
    private toastrService: ToastrService,
    private title: Title,
    private loggerService: LoggerService) {
    $('body').css('cursor', 'wait')
    this.authService.isLoggedIn().then(data => {
      if (data) {
        this.toastrService.warning("Please logout if you wish to complete signup with different account", "You are already logged in")
        this.router.navigate(['dashboard'])
      } else {
        $('body').css('cursor', '')
      }

    })
  }

  ngOnInit() {
    //window.location.reload()
    $('body').css('cursor', '')
    this.title.setTitle("Complete Signup")
    this.toastrService.info("Hello")

  }

  async checkEmail(loginForm: NgForm) {
    try {
      $('body').css('cursor', 'wait')
      if (!this.form.email) {
        this.toastrService.error("Email could be missing", "Form is not valid yet")
        console.log(
          `Form is not valid yet, current value: ${loginForm.value}`
        );
      } else {
        this.toastrService.info("","Checking Email")
        let invitation = await this.userService.checkInvitationEmail(this.form.email)
        console.log(invitation)
        this.form.invitationId = invitation.id
        $('body').css('cursor', '')
        $("#passwordField").show()
        $("#nextStepBtn").hide()
        $("#createUserBtn").show()
        this.toastrService.success("","User Found")

      }
    } catch (error) {
      let msg = error['messsage'] || error.toString()
      this.toastrService.error(`${msg}`, 'Error occur when invite user!')
    }
  }

  async createUser(){
    try {
      $('body').css('cursor', 'wait')
      if(!this.form.email || !this.form.password || !this.form.invitationId){
        this.toastrService.error("email or password or invitation ID is missing","Missing Field")
      }
      this.userService.createUser(this.form)
    } catch (error) {
      let msg = error['messsage'] || error.toString()
      this.toastrService.error(`${msg}`, 'Error occur when invite user!')
    }

  }

  login(loginForm: NgForm): void {

    $('body').css('cursor', 'wait')

    if (!loginForm.valid) {
      this.toastrService.error("Form is not valid yet")
      console.log(
        `Form is not valid yet, current value: ${loginForm.value}`
      );
    } else {
      this.toastrService.info("Logging")
      //console.log(this.form)
      this.authService.login(this.form.email, this.form.password).then(authData => {
        this.toastrService.info("Authenticated")
        //console.log(authData)
        this.userService.getUser().then(data => {
          this.toastrService.info("Checking User Status")
          //console.log(data);
          if (!data) {
            console.log('user data missing')
            $('body').css('cursor', '')
            return this.toastrService.error('Please contact support for further checking', "Can't find User Access & Status")
          }
          if (data && data['status'] == 'active') {
            console.log('is active user')
            //localStorage.setItem('isLoggedin', 'true');
            this.router.navigate(['/dashboard']);
          }
          else {
            console.log('not active user')
            $('body').css('cursor', '')
            this.toastrService.error('Please contact your boss for further checking', 'Your Account is not Active!')
          }
        },
          userError => {
            $('body').css('cursor', '')
            this.toastrService.error(userError, "Unable to receive User Data")
          });
      },
        error => {
          $('body').css('cursor', '')
          console.log(error);
          this.toastrService.error(error, 'Login Failed')
        }
      );
    }
  }

}
