import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { HttpService } from '../http/http.service'






@Injectable({
  providedIn: 'root'
})
export class UtilService {
  currentUser:firebase.User;
  
    constructor(
    private httpService: HttpService
  ) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.currentUser = user;
        console.log('Leads services loaded');
        //console.log(Date.parse("2018-08-08"))

      }
      else {
        return false;
      }
    });
  }
  
  generateDateTime() {
    let addZero = function (i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    let timestamp = Date.now()
    let year = new Date(timestamp).getFullYear()
    let month = new Date(timestamp).getMonth() + 1 < 10 ? '0' + (new Date(timestamp).getMonth() + 1) : new Date(timestamp).getMonth() + 1
    let day = new Date(timestamp).getDate() < 10 ? '0' + new Date(timestamp).getDate() : new Date(timestamp).getDate()
    let hour = addZero(new Date(timestamp).getHours());
    let minute = addZero(new Date(timestamp).getMinutes());
    let second = addZero(new Date(timestamp).getSeconds());

    let newDate = year.toString() + month + day + " " + hour + minute + second
    return newDate
  }

  yyDDmm(timestamp:number|null){
    if(!timestamp){
      timestamp = Date.now()
    }
    let year = new Date(timestamp).getFullYear()
    let month = new Date(timestamp).getMonth() + 1 < 10 ? '0' + (new Date(timestamp).getMonth() + 1) : new Date(timestamp).getMonth() + 1
    let day = new Date(timestamp).getDate() < 10 ? '0' + new Date(timestamp).getDate() : new Date(timestamp).getDate()
    
    return year.toString() +'-'+ month +'-'+ day
  }

  mmmYYYY(timestamp){
    if(!timestamp){
      timestamp = Date.now()
    }
    let year = new Date(timestamp).getFullYear()
    let month = new Date(timestamp).getMonth()
    let months = {
      0:"Jan",
      1:"Feb",
      2:"Mar",
      3:"Apr",
      4:"May",
      5:"Jun",
      6:"Jul",
      7:"Aug",
      8:"Sep",
      9:"Oct",
      10:"Nov",
      11:"Dec"
    }
    return months[month] + '-'+year
  }

  mmYY(timestamp?:number){
    if(!timestamp){
      timestamp = Date.now()
    } 
    let addZero = function (i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i.toString();
    }

    let month = new Date(timestamp).getMonth() + 1 < 10 ? '0' + (new Date(timestamp).getMonth() + 1) : new Date(timestamp).getMonth() + 1
    let mm = month.toString()
    let year = new Date(timestamp).getFullYear()
    let yy = year.toString().split("").slice(2,4).join("")

    let newDate = mm+yy
    
    return newDate

  }

  yyyyMMddHHmmSS(timestamp?:number) {
    let addZero = function (i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i.toString();
    }
    if(!timestamp){
      timestamp = Date.now()
    } 

    let year = new Date(timestamp).getFullYear()
    let month = new Date(timestamp).getMonth() + 1 < 10 ? '0' + (new Date(timestamp).getMonth() + 1) : new Date(timestamp).getMonth() + 1
    let day = new Date(timestamp).getDate() < 10 ? '0' + new Date(timestamp).getDate() : new Date(timestamp).getDate()
    let hour = addZero(new Date(timestamp).getHours());
    let minute = addZero(new Date(timestamp).getMinutes());
    let second = addZero(new Date(timestamp).getSeconds());

    let newDate = year.toString() +"-"+ month +"-"+ day + " " + hour+":" + minute+":" + second
    return newDate
  }


}
