import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { HttpService } from '../http/http.service'
import { UserService } from '../user/user.service'

import { iwo } from '../../model/iwo.model'
import { reject } from 'q';




@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  currentUser;
  leadsRef: firebase.database.Reference
  targetRef: firebase.database.Reference
  allLeads = []
  allLeadsReady = false
  constructor(
    private httpService: HttpService,
    private userService: UserService,
  ) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.userService.getCurrentUser().then(user => {
          this.currentUser = user
          console.log('Leads services loaded', this.currentUser);
          //console.log(Date.parse("2018-08-08"))
          this.leadsRef = firebase.database().ref(`/leads`);
          this.targetRef = firebase.database().ref(`/targets`);
          this.getAllLeads().then(data => {
            this.allLeadsReady = true
            this.monitorLeadsChange()
          })

        })
      }
      else {
        return false;
      }
    });
  }

  async getAllLeads() {
    try {

      console.log("[getAllLeads]", "getting all leads", this.currentUser.access.features.salesDashboard.subFeatureOrField.allLeads.read)
      if (this.currentUser.access.features.salesDashboard.subFeatureOrField.allLeads.read == true) {
        let leads = await this.pagination(0, 50, [])
        this.allLeads = leads
        // console.log(leads)

        return leads

      } else {
        let leads = await this.getLeadsByEmail(this.currentUser.email)
        this.allLeads = leads
        // console.log(leads)

        return leads

      }
    } catch (error) {
      console.log("[getAllLeads] error", error)
      throw error
    }
  }


  async pagination(key, num, data: any[]) {
    try {
      let snap = await this.leadsRef.orderByChild("dtcreate").startAt(key).limitToFirst(num).once('value')
      console.log(snap.val())
      if (snap.val()) {
        let lastKey = ""
        snap.forEach(e => {
          data.push({ ...e.val(), key: e.key })
          lastKey = e.val()["dtcreate"] + 1
        })
        // console.log(data)
        if (snap.numChildren() == num) {
          console.log("looping")
          return await this.pagination(lastKey, num, data)
        } else {
          return data
        }
      }
      else {
        return data
      }
    } catch (error) {
      console.log("[leads ]", "pagination: Error", error)
      throw error
    }

  }

  async getLeadsByEmail(email) {
    try {
      let data = []
      let snap = await this.leadsRef.orderByChild("owner").equalTo(email).once('value')
      console.log(snap.val())
      if (snap.val()) {
        snap.forEach(e => {
          data.push({ ...e.val(), key: e.key })
        })
        console.log(data)
        return data
      }
      else {
        return data
      }
    } catch (error) {
      console.log("[leads ]", "pagination: Error", error)
      throw error
    }

  }

  monitorLeadsChange() {
    if (this.currentUser.access.features.salesDashboard.subFeatureOrField.allLeads.read == true) {
      this.leadsRef.on("child_added", snap => {
        let i = this.allLeads.findIndex(e => { return e.key == snap.key })
        if (i < 0) {
          this.allLeads.push({ ...snap.val(), key: snap.key })
          console.log(this.allLeads)
        }
      })
      this.leadsRef.on("child_changed", snap => {
        let i = this.allLeads.findIndex(e => { return e.key == snap.key })
        this.allLeads[i] = { ...snap.val(), key: snap.key }
        console.log(this.allLeads)
      })
      this.leadsRef.on("child_removed", snap => {
        let i = this.allLeads.findIndex(e => { return e.key == snap.key })
        this.allLeads.splice(i, 1)
        console.log(this.allLeads)
      })
    } else {
      this.leadsRef.orderByChild("owner").equalTo(this.currentUser.email).on("child_added", snap => {
        let i = this.allLeads.findIndex(e => { return e.key == snap.key })
        if (i < 0) {
          this.allLeads.push({ ...snap.val(), key: snap.key })
          console.log(this.allLeads)
        }
      })
      this.leadsRef.orderByChild("owner").equalTo(this.currentUser.email).on("child_changed", snap => {
        let i = this.allLeads.findIndex(e => { return e.key == snap.key })
        this.allLeads[i] = { ...snap.val(), key: snap.key }
        console.log(this.allLeads)
      })
      this.leadsRef.orderByChild("owner").equalTo(this.currentUser.email).on("child_removed", snap => {
        let i = this.allLeads.findIndex(e => { return e.key == snap.key })
        this.allLeads.splice(i, 1)
        console.log(this.allLeads)
      })

    }
  }

  list(status) {
    if (status) {
      return this.leadsRef.orderByChild("status").equalTo(status)
    } else {
      return this.leadsRef
    }
  }

  get(id: string) {
    return this.leadsRef.child(id).once("value")
  }

  add(leadsRecord) {
    return new Promise(resolve => {
      this.generateReferenceNumber(leadsRecord["client"]["name"], leadsRecord["projectType"]).then(data => {
        console.log("reference number",data)
        let timestamp = Date.now();
        leadsRecord['refNum'] = data['refNum']
        leadsRecord['index'] = data['index']
        leadsRecord['indexYear'] = new Date(timestamp).getFullYear();
        leadsRecord['dtmodify'] = timestamp;
        leadsRecord['status'] = 'open'

        delete leadsRecord["id"]
        leadsRecord["dtcreate"] = firebase.database.ServerValue.TIMESTAMP
        delete leadsRecord["checkbox"]
        let obj = {}
        Object.assign(obj, leadsRecord)
        console.log(obj)
        this.leadsRef.push({ ...obj }).then(inserted=>{
          resolve(inserted)
        })
      }).catch(error=>{
        console.log("reference number error",error)
        reject(error)
      })
    })
  }

  update(id: string, leadsRecord) {
    leadsRecord["dtmodified"] = firebase.database.ServerValue.TIMESTAMP
    delete leadsRecord["checkbox"]
    return this.leadsRef.child(id).update(leadsRecord)
  }



  delete(id: string) {
    return this.leadsRef.child(id).remove()
  }

  addTarget(uid, target, dtstart, dtend) {
    return this.targetRef.push({
      uid,
      value: target,
      dtstart,
      dtend
    })
  }

  updateTarget(uid, id, target, dtstart, dtend) {
    this.targetRef.child(id).update({
      uid,
      value: target,
      dtstart,
      dtend
    })
  }

  listTarget(uid: string | null) {
    console.log("listTarget", uid)
    if (uid) {
      return this.targetRef.orderByChild("uid").equalTo(uid).once("value")
    } else {
      return this.targetRef.once("value")
    }

  }


  generateReferenceNumber(name, projectType) {
    if (!name) {
      throw new Error("name is missing")
    }

    if (!projectType) {
      throw new Error("project type is missing")
    }

    name = name.match(/\w/gm).join("").slice(0, 5).toUpperCase()
    if (projectType == 'project') {
      projectType = "Q"
    } else if (projectType == 'tender') {
      projectType = "Q"
    } else {
      projectType = "Q"
    }
    let index = 1
    let refNum //refNum format = [companyname]/[Q or P]-[runningNumber]/[month][year]
    let timestamp = Date.now()
    let year = new Date(timestamp).getFullYear()
    let month = new Date(timestamp).getMonth() + 1 < 10 ? '0' + (new Date(timestamp).getMonth() + 1) : new Date(timestamp).getMonth() + 1
    let day = new Date(timestamp).getDate() < 10 ? '0' + new Date(timestamp).getDate() : new Date(timestamp).getDate()
    //console.log(timestamp, year, month, day)
    return new Promise(resolve => {
      this.leadsRef.orderByChild('indexYear').equalTo(year).limitToLast(1).once('value', snap => {
        console.log(snap.numChildren(),snap.val())
        if (snap.val() === null || snap.val() === undefined) {
          let newYear = year - 2000
          refNum = name + '/' + projectType + '-000' + index + '/' + month + newYear
          console.log(refNum)
          let object = { refNum: refNum, index: index }
          resolve(object)
        }
        else {
          let tempArray = []
          snap.forEach(element => {
            tempArray.push(element.val().index)
          });
          tempArray = tempArray.filter(tempArrayElement => tempArrayElement !== undefined)
          //console.log(tempArray)
          //console.log(Math.max(...tempArray))
          index = Math.max(...tempArray)
          index = index + 1
          let newYear = year - 2000
          if (index < 10) {
            refNum = name + '/' + projectType + '-000' + index + '/' + month + newYear
          }
          else if (index < 100 && index >= 10) {
            refNum = name + '/' + projectType + '-00' + index + '/' + month + newYear
          }
          else if (index < 1000 && index >= 100) {
            refNum = name + '/' + projectType + '-0' + index + '/' + month + newYear
          }
          else {
            refNum = name + '/' + projectType + '-' + index + '/' + month + newYear
          }
          //console.log(refNum)
          let object = { refNum: refNum, index: index }
          console.log(object)
          resolve(object)
        }
      })
    })



  }


}
