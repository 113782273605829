import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';


import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import { LoggerService } from '../../services/logger/logger.service';
import { LocalService } from '../../services/local/local.service';

import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],

})
export class LoginComponent implements OnInit {
  form = {
    email: '',
    password: ''
  };

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private location: Location,
    private toastrService: ToastrService,
    private localService: LocalService,
    private title: Title,
    private loggerService:LoggerService) {
    $('body').css('cursor', 'wait')
    this.authService.isLoggedIn().then(data => {
      data ? this.router.navigate(['dashboard']) : $('body').css('cursor', '')

    })
  }

  ngOnInit() {
    //window.location.reload()
    $('body').css('cursor', '')
    this.title.setTitle("Login")
    this.toastrService.info("Hello")

  }

  login(loginForm: NgForm): void {
    
    $('body').css('cursor', 'wait')

    if (!loginForm.valid) {
      this.toastrService.error("Form is not valid yet")
      console.log(
        `Form is not valid yet, current value: ${loginForm.value}`
      );
    } else {
      this.toastrService.info("Logging")
      console.log(this.form)
      this.authService.login(this.form.email, this.form.password).then(authData => {
        this.toastrService.info("Authenticated")
        //console.log(authData)
        this.userService.getUser().then(data => {
          this.toastrService.info("Checking User Status")
          //console.log(data);
          if(!data){
            console.log('user data missing')
            $('body').css('cursor', '')
            return this.toastrService.error('Please contact support for further checking', "Can't find User Access & Status")
          }
          if (data && data['status'] == 'active') {
            console.log('is active user')
            //localStorage.setItem('isLoggedin', 'true');
            let redirectUrl = this.localService.getLocal('loginBackToPath')
            if(!redirectUrl){
              this.router.navigate(['/dashboard']);
            }else{
              this.router.navigate(['/'+redirectUrl]);
              this.localService.getLocalAndRemove('loginBackToPath')
            }
          }
          else {
            console.log('not active user')
            $('body').css('cursor', '')
            this.toastrService.error('Please contact your boss for further checking', 'Your Account is not Active!')
          }
        },
        userError=>{
          $('body').css('cursor', '')
          this.toastrService.error(userError,"Unable to receive User Data")
        });
      },
        error => {
          $('body').css('cursor', '')
          console.log(error);
          this.toastrService.error(error, 'Login Failed')
        }
      );
    }
  }

}
