import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { LoggerService } from '../logger/logger.service'
import { HttpService } from '../http/http.service'
import { AuthService } from '../auth/auth.service'

//import { User } from '../../model/user.model';

import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userList: AngularFireList<any>;
  //selectedUser: User = new User();
  user: firebase.database.Reference;
  currentUser;
  usersRef: firebase.database.Reference;
  userConfigRef: firebase.database.Reference;;
  invitationRef: firebase.database.Reference;;
  systemConfigRef
  currentUserId

  constructor(private afDb: AngularFireDatabase,
    private http: Http,
    private loggerService: LoggerService,
    private httpService: HttpService,
    private authService: AuthService,
  ) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        //this.currentUser = user;
        this.usersRef = firebase.database().ref(`/users`);
        this.userConfigRef = firebase.database().ref(`/userConfig`);
        this.systemConfigRef = firebase.database().ref(`/systemConfig`)
        this.invitationRef = firebase.database().ref(`/invitation`)
        // this.usersRef.once('value',snap=>{
        //   console.log(snap.val()["3Akvq2sJdifzFp2gZNXaMWq7oF53"])
        // })
        this.currentUserId = user.uid
        
        this.getCurrentUser()
        console.log('user services loaded', user);
      }
    });
  }

  getUser() {
    return new Promise(resolve => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          firebase.database().ref(`/users/${user.uid}`).on('value', snap => {
            resolve(snap.val());
          });
        }
      });
    });
  }

  getCurrentUser() {
    return new Promise(resolve => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          this.loggerService.featureAccessCounting('userService', 'getCurrentUser')

          //console.log(user)
          firebase.database().ref(`/users/${user.uid}`).once('value', snap => {

            //console.log(snap.key)
            if (snap.val() !== null) {
              this.currentUser = snap.val()
              this.currentUser['uid'] = user.uid
              // console.log(this.currentUser)
              let tempRole = this.currentUser.role
              tempRole = tempRole.split(" ")
              let role = tempRole[0]

              for (var i = 1; i < tempRole.length; i++) {
                let str = tempRole[i].charAt(0).toUpperCase() + tempRole[i].slice(1).toLowerCase();
                role = role + str
              }
              //console.log(role)
              this.getRoleAccess().then(data => {
                if (data[role]) {
                  //console.log(data[role])
                  this.currentUser['access'] = data[role]
                  //console.log(this.currentUser)
                  return resolve(this.currentUser);
                }
                resolve(false);
              });
            }
          })
        };
      });
    })
  }

  getUserById(userId) {
    return new Promise(resolve => {
      // firebase.auth().onAuthStateChanged(user => {
      //   if (user) {
          firebase.database().ref(`/users/${userId}`).on('value', snap => {
            resolve(snap.val());
          });
      //   }
      // });
    });
  }

  getAllUsers() {
    return this.usersRef;
  }

  getUserConfig() {
    return this.userConfigRef.child("role").orderByKey();
  }

  getUserStatus() {
    return this.userConfigRef.child("status").orderByKey();
  }

  async insertUser(user, uid) {
    try {
      console.log(uid, user);
      let invitation = await this.checkInvitationEmail(user.email)
      let obj = {
        email: user.email,
        status: invitation.status,
        role: invitation.role,
        dtcreate: Date.now(),
        dtmodify: Date.now(),
        invitationId: invitation.id
      }
      console.log(obj)
      let addedUid = await this.invitationRef.child(invitation.id).update({uid})
      console.log(addedUid)
      return this.usersRef.child(uid).set(obj);
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  updateUser(user, uid) {
    return this.usersRef.child(`${uid}`).update({
      email: user.email,
      status: user.status,
      role: user.role,
      dtmodify: Date.now()
    });
  }

  deleteUser($key: string) {
    this.userList.remove($key);
  }

  getRoleAccess() {
    return new Promise(resolve => {
      this.userConfigRef.child("role").on('value', snap => {
        resolve(snap.val())
      })
    })
  }

  getRoleTemplate() {
    return this.userConfigRef.child("roleTemplate")
  }

  createRole(name, access) {
    return new Promise((resolve, reject) => {
      this.getRoleAccess().then(roles => {
        let keys = Object.keys(roles)
        if (keys.includes(name)) {
          reject("access name already exist")
        } else {
          this.userConfigRef.child("role").child(name).set({ features: access }).then(setted => {
            resolve(true)

          }).catch(error => {
            reject(error)
          })
        }
      })
    })
  }
  updateRole(name, access) {
    return new Promise((resolve, reject) => {
      this.getRoleAccess().then(roles => {
        let keys = Object.keys(roles)
        if (keys.includes(name)) {
          this.userConfigRef.child("role").child(name).update({ features: access }).then(setted => {
            resolve(true)
          }).catch(error => {
            reject(error)
          })

        } else {
          reject("access name not exist")

        }
      })
    })
  }

  async createUser(newUser) {
    console.log(newUser)
    try {
      if (!newUser.email || !newUser.password || !newUser.invitationId) {
        throw "email or password or invitation ID is missing"
      }
      let userRecord = await this.authService.signup(newUser.email, newUser.password)
      console.log(userRecord)
      let userDbData = await this.insertUser(newUser, userRecord.user.uid)

      console.log("successfully insert user to DB")
      return userRecord.user

    } catch (error) {
      console.log("Error happened when inserting user to DB")
      console.log(error)
      throw error
    }
  }

  async inviteUser(newUser) {
    console.log(newUser)
    try {
      if (!newUser.email || newUser.Password) {
        throw "email or password is missing"
      }
      let inserted = await this.invitationRef.push({
        email: newUser.email,
        dtcreate: Date.now(),
        role: newUser.role,
        status: newUser.status
      })
      return inserted.key

    } catch (error) {
      console.log("Error happened when inserting user to DB")
      console.log(error)
      throw error
    }
  }

  async checkInvitationEmail(email) {
    try {
      if (!email) {
        throw "email is missing"
      }
      let snap = await firebase.database().ref("invitation").orderByChild("email").limitToLast(1).once("value")
      if (snap.exists()) {
        let val = snap.val()
        let id = Object.keys(val)[0]
        let obj = {
          id,
          ...val[id]
        }
        return obj
      } else {
        throw new Error("invalid email or email doesn't exist")
      }
    } catch (error) {
      console.log("Error happened when check invitation")
      console.log(error)
      throw error
    }
  }



  lastLogin() {
    return this.usersRef.child(this.currentUser.uid).update({ dtLogin: Date.now() })
  }

  getVersion() {
    return this.systemConfigRef.child("version")
  }

  setHardRefreshDate() {
    let today = this.generateTodayDate()
    return this.usersRef.child(this.currentUser.uid).update({ dtHardRefresh: today })
  }

  getHardRefreshDate() {
    return this.usersRef.child(this.currentUser.uid + "/dtHardRefresh")
  }




  getUserDeviceInfo(): Promise<cloudflare> {
    return new Promise((resolve, reject) => {
      this.httpService.cloudflare().subscribe(data => {
        let Obj = data.trim().split('\n').reduce(function (obj, pair) {
          let arr = pair.split('=');
          return obj[arr[0]] = arr[1], obj;
        }, {});
        let res: cloudflare = {
          ip: Obj["ip"],
          uag: Obj["uag"]
        }
        return resolve(res)
      })
    })
  }

  generateTodayDate() {
    let addZero = function (i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    let timestamp = Date.now()
    let year = new Date(timestamp).getFullYear()
    let month = new Date(timestamp).getMonth() + 1 < 10 ? '0' + (new Date(timestamp).getMonth() + 1) : new Date(timestamp).getMonth() + 1
    let day = new Date(timestamp).getDate() < 10 ? '0' + new Date(timestamp).getDate() : new Date(timestamp).getDate()
    let hour = addZero(new Date(timestamp).getHours());
    let minute = addZero(new Date(timestamp).getMinutes());
    let second = addZero(new Date(timestamp).getSeconds());

    let newDate = year.toString() + '-' + month + '-' + day
    return newDate
  }

}

export class cloudflare {
  "ip": string;
  "uag": string;
}