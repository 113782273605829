import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';

import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from '../environments/environment';

import { ToastrModule } from 'ngx-toastr';



import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import services
import { ClientService } from './services/client/client.service';
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/user/user.service';
import { EquipmentService } from './services/equipment/equipment.service';
import { OrderService } from './services/order/order.service';
import { CloudStorageService } from './services/cloud-storage/cloud-storage.service';
import { LoggerService } from './services/logger/logger.service';
import { HttpService } from './services/http/http.service';
import { ExcelService } from './services/excel/excel.service';
import { ScrollHistoryService } from './services/scroll-history/scroll-history.service';
import { DistributionService } from './services/distribution/distribution.service';
import { LeadsService } from './services/leads/leads.service';
import { SalesActivitiesService } from './services/sales-activities/sales-activities.service';
import { SupplierService } from './services/supplier/supplier.service';
import { WarehouseService } from './services/warehouse/warehouse.service';
import { UtilService } from './services/util/util.service';


// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component'
import { CompleteSignupComponent } from './views/complete-signup/complete-signup.component'

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular'

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { DataTablesModule } from 'angular-datatables';
import { Ng2CompleterModule } from 'ng2-completer';
import { ModalModule } from 'ngx-bootstrap';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    DataTablesModule,
    Ng2CompleterModule,
    AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        AngularFireAuthModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    CompleteSignupComponent
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: PathLocationStrategy
  },
    ClientService,
    AuthService,
    UserService,
    EquipmentService,
    OrderService,
    CloudStorageService,
    LoggerService,
    HttpService,
    ExcelService,
    ScrollHistoryService,
    DistributionService,
    LeadsService,
    SalesActivitiesService,
    SupplierService,
    WarehouseService,
    UtilService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
