import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';
import 'firebase/storage'

@Injectable({
  providedIn: 'root'
})
export class CloudStorageService {
  iwoRef
  leadsRef: firebase.database.Reference
  iwoStorageRef
  leadStorageRef: firebase.storage.Reference
  staticStorageRef
  constructor() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.iwoRef = firebase.database().ref(`/iwo`);
        this.leadsRef = firebase.database().ref(`/leads`);
        this.iwoStorageRef = firebase.storage().ref('iwo')
        this.leadStorageRef = firebase.storage().ref('leads')
        this.staticStorageRef = firebase.storage().ref('static')
      }
    });
  }

  uploadToFirebase(folderName, fileName, file) {
    //let folder = this.storageRef.child(folderName)
    //let docName = folderName.child(fileName)
    return this.iwoStorageRef.child(`${folderName}/${fileName}`).put(file)

  }
  uploadToFirebaseLeadsFolder(folderName, fileName, file) {
    //let folder = this.storageRef.child(folderName)
    //let docName = folderName.child(fileName)
    return this.leadStorageRef.child(`${folderName}/${fileName}`).put(file)

  }

  getFileURL(folderName, fileName) {
    return this.iwoStorageRef.child(`${folderName}/${fileName}`).getDownloadURL()
  }

  getFileURLFromLeadsStorage(folderName, fileName) {
    return this.leadStorageRef.child(`${folderName}/${fileName}`).getDownloadURL()
  }

  getStaticFileURL(fileName) {
    return this.staticStorageRef.child(`${fileName}`).getDownloadURL()
  }

  insertURLtoDB(iwoId, document) {
    return this.iwoRef.child(`${iwoId}/document`).push(document)
  }

  insertURLtoLeadsDB(leadId, document) {
    return this.leadsRef.child(`${leadId}/document`).push(document)
  }

  deleteDoc(iwoId, document) {
    return this.iwoRef.child(`${iwoId}/document/${document.docId}`).update({ status: "deleted" })
  }

  deleteDocFromLeadsDB(leadId, document) {
    return new Promise((resolve, reject) => {

    

      this.leadsRef.child(`${leadId}/document`).orderByChild("id").equalTo(document.id).once("value", snap => {
        if (snap.exists()) {
          console.log(snap.key,snap.val())
          snap.forEach(each=>{
            console.log(each.key,each.val())
            if(each.val()){
              let index = each.key
              this.leadsRef.child(`${leadId}/document/${index}`).update({ status: "deleted" }).then(resolve).catch(reject)
    
            }
          })
        }else{
          this.leadsRef.child(`${leadId}/document/${document.id}`).once("value", snap2 => {

            if(snap2.exists()){
              this.leadsRef.child(`${leadId}/document/${document.id}`).update({ status: "deleted" }).then(resolve).catch(reject)
            }else{
              resolve(true)

            }

          })
        }
      })
    })
  }
  

}
