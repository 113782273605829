export const navItems = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    id:'dashboard',
    panel:'iwo'
  },
  // {
  //   name: 'Search Result',
  //   url: '/search-results',
  //   icon: 'icon-magnifier'
  // },
  {
    name: 'Create IWO',
    url: '/create-iwo',
    icon: 'icon-doc',
    id:'createIwo',
    panel:'iwo'
  },
  {
    name: 'IWO List',
    url: '/orders',
    icon: 'icon-doc',
    id:'orderList',
    panel:'iwo'
  },
  {
    name: 'Distribution',
    url: '/distribution',
    icon: 'icon-people',
    id:'distribution',
    panel:'iwo'
  },
  {
    name: 'All Clients',
    url: '/all-clients',
    icon: 'icon-people',
    id:'allClient',
    panel:'iwo'
  },
  {
    name: 'User Management',
    url: '/user-management/manage-users',
    icon: 'icon-people',
    id:'usersDashboard',
    panel:'users'
  },
  {
    name: 'Access List',
    url: '/user-management/access-list',
    icon: 'icon-people',
    id:'accessList',
    panel:'users'
  },
  {
    name: 'Activities Log',
    url: '/activities',
    icon: 'icon-people',
    id:'activitiesLog',
    panel:'users'
  },
  {
    name: 'Stock Management',
    url: '/stock-management/manage-stocks',
    icon: 'icon-basket-loaded',
    id:'stockManagement',
    panel:'iwo'
    // children: [
    //   {
    //     name: 'Manage Stocks',
    //     url: '/stock-management/manage-stocks',
    //     icon: 'icon-drawer'
    //   },
    //   {
    //     name: 'Add Stock',
    //     url: '/stock-management/add-stock',
    //     icon: 'icon-plus'
    //   }
    // ]
  },
  {
    name: 'Dashboard',
    url: '/sales-funnel/',
    icon: 'nav-icon icon-speedometer',
    id:'leadsDashboard',
    panel:'sales'
  },
  {
    name: 'Add Leads',
    url: '/sales-funnel/leads-form/',
    icon: 'icon-plus',
    id:'addLeads',
    panel:'sales'
    // children: [
    //   {
    //     name: 'Dashboard',
    //     url: '/sales-funnel/',
    //     icon: 'icon-speedometer'
    //   },
    //   {
    //     name: 'Add Leads',
    //     url: '/sales-funnel/leads-form',
    //     icon: 'icon-plus'
    //   }
    // ]
  },
  // {
  //   name: 'Reports',
  //   url: '/sales-funnel/reports',
  //   icon: 'nav-icon icon-doc',
  //   id:'leadsReport',
  //   panel:'sales'
  // },
  {
    name: 'Pipeline Reports',
    url: '/sales-funnel/reports',
    icon: 'icon-chart',
    id:'leadsReport',
    panel:'sales'
  },
  {
    name: 'Sales Activities',
    url: '/sales-funnel/activities',
    icon: 'icon-notebook',
    id:'salesActivities',
    panel:'sales'
  },
  {
    name: 'Dashboard',
    url: '/warehouse/',
    icon: 'nav-icon icon-speedometer',
    id:'warehouseDashboard',
    panel:'warehouse'
  },
  {
    name: 'Add Stock',
    url: '/warehouse/add-stock-form/',
    icon: 'icon-plus',
    id:'addStock',
    panel:'warehouse'
  },
  {
    name: 'Stock Activities',
    url: '/warehouse/activities',
    icon: 'icon-notebook',
    id:'warehouseActivities',
    panel:'warehouse'
  },
  {
    name: 'Loan Management',
    url: '/warehouse/loan-management',
    icon: 'icon-notebook',
    id:'warehouseLoanManagement',
    panel:'warehouse'
  }
];
