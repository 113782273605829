import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  currentUser
  logRef: firebase.database.Reference
  usageRef: firebase.database.Reference
  counterRef: firebase.database.Reference
  changeLogRef: firebase.database.Reference

  constructor() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.currentUser = user;
        this.logRef = firebase.database().ref(`/activities`);
        this.usageRef = firebase.database().ref(`/usage`);
        this.counterRef = firebase.database().ref(`/counter`);
        this.changeLogRef = firebase.database().ref(`/changeLog`);
        // let obj = {
        //   dtcreate:Date.now(),
        //   date:"2022-06-06",
        //   version:"1.7.2",
        //   newFeature:[],
        //   enhancement:[],
        //   bugfix:["Prevent multiple IWO submission by spamming submit button.",]
        // }
        // obj["title"] = `Version ${obj.version} (${obj["date"]})`
        // this.changeLogRef.push(obj)
        console.log('Added code versioning checking between local and server');
      }
      else {
        return false;
      }
    });
  }

  getChangeLog(){
    return this.changeLogRef
  }

  log(status, action, req, res: Object, site) {
    let object = {
      dtcreate: Date.now(),
      uid: this.currentUser.uid,
      userEmail: this.currentUser.email,
      action: action,
      req: req,
      res: res,
      site: site,
      status: status
    }
    let err = {}
    if (res instanceof Error) {
      err["message"] = res.message
      err["name"] = res.name
      res.stack ? err["message"] = res.stack : ""
      object.res = err
    }
    return this.logRef.push(object)
  }

  getAllLog() {
    return this.logRef
  }

  getTodayLog() {
    let today = this.generateTodayDate()
    let unixToday = Date.parse(today) - 28800000
    console.log(unixToday)
    return this.logRef.orderByChild(`dtcreate`).startAt(unixToday)
  }

  getLogByDateRange(dtStart, dtEnd) {
    return this.logRef.orderByChild('dtcreate').startAt(dtStart).endAt(dtEnd)
  }

  getLogByCriteria(property, value) {
    return this.logRef.orderByChild(property).equalTo(value)
  }


  featureAccessCounting(page, feature) {
    let today = this.generateTodayDate()
    console.log(today, page, feature)
    let a = Object.assign({}, this.usageRef)
    // console.log(a)
    // console.log(this.usageRef?true:false)
    // console.log(this.usageRef.child ?true:false)
    this.usageRef.child(`${today}`).once("value", todaySnap => {
      if (todaySnap.val()) {
        console.log(todaySnap.val())
        this.usageRef.child(`${today}/${page}`).once("value", snap => {
          let data = snap.val()
          console.log(data)
          if (data && data[feature] && data[feature].count) {
            let currentCount = data[feature].count
            currentCount++
            this.usageRef.child(`${today}/${page}/${feature}`).update({ count: currentCount })
          }
          else {
            this.usageRef.child(`${today}/${page}/${feature}`).set({ count: 1 })
          }
        })
      }
      else {
        this.usageRef.child(`${today}/${page}/${feature}`).set({ count: 1 })
      }
    })
    return
  }

  orderCounter(newStatus, exStatus?) {
    this.counterRef.child(`orderStatusCounter`).once("value", snap => {
      if (snap.val() && snap.val()[newStatus]) {
        let newStatusCurrentCount = snap.val()[newStatus].count
        newStatusCurrentCount++
        this.counterRef.child(`orderStatusCounter/${newStatus}`).update({ count: newStatusCurrentCount })
      }
      else {
        this.counterRef.child(`orderStatusCounter/${newStatus}`).set({ count: 1 })
      }

      if (exStatus && snap.val() && snap.val()[exStatus] && snap.val()[exStatus].count > 0) {
        let exStatusCurrentCount = snap.val()[exStatus].count
        exStatusCurrentCount--
        this.counterRef.child(`orderStatusCounter/${exStatus}`).update({ count: exStatusCurrentCount })
      }

    })
  }

  clientCounter() {
    this.counterRef.child(`clientCounter`).once('value', snap => {
      if (snap.val() && snap.val().new) {
        let currentCounter = snap.val().new.count
        currentCounter++
        this.counterRef.child(`clientCounter/new`).update({ count: currentCounter })
      }
      else {
        this.counterRef.child(`clientCounter/new`).set({ count: 1 })
      }
    })
  }

  getClientCount() {
    return this.counterRef.child(`clientCounter`)
  }
  getOrderCount() {
    return this.counterRef.child(`orderStatusCounter`)
  }

  generateTodayDate() {
    let addZero = function (i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    let timestamp = Date.now()
    let year = new Date(timestamp).getFullYear()
    let month = new Date(timestamp).getMonth() + 1 < 10 ? '0' + (new Date(timestamp).getMonth() + 1) : new Date(timestamp).getMonth() + 1
    let day = new Date(timestamp).getDate() < 10 ? '0' + new Date(timestamp).getDate() : new Date(timestamp).getDate()
    let hour = addZero(new Date(timestamp).getHours());
    let minute = addZero(new Date(timestamp).getMinutes());
    let second = addZero(new Date(timestamp).getSeconds());

    let newDate = year.toString() + '-' + month + '-' + day
    return newDate
  }


  deleteLogsByDateRange(key) {
    return this.logRef.child(key).remove()
  }



}

