import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  currentUser;
  equipRef;

  constructor() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.currentUser = user;
        this.equipRef = firebase.database().ref(`/equipment`);
        console.log('equipment services loaded');
      }
    });
  }

  getAllStock() {
    return this.equipRef.child('stock');
  }

  getAllStock2() {
    let stockArray = []
    return new Promise(resolve => {
      this.equipRef.child('stock').once('value', snap => {
        if (snap.val()) {
          snap.forEach(element => {
            let object = element.val()
            object['id'] = element.key
            stockArray.push(object)
          });
          resolve(stockArray)
        }

      })
    })
  }

  getStockList(){
    return this.equipRef.child('stock')
  }

  getStockByBrand(brand){
    return this.equipRef.child('stock').orderByChild('brand').equalTo(brand)
  }


  addStockList(stock){
    return this.equipRef.child('stock').push(stock)
  }

  updateStockQuantity(stockId,stock){
    return this.equipRef.child(`stock/${stockId}`).update(stock)
  }
}
