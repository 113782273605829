import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';

import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase/app'

// import {UserService} from '../user/user.service'



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Observable<firebase.User>;
  authRef:firebase.auth.Auth
  userDbRef:firebase.database.Database

  constructor(private afAuth: AngularFireAuth,
    private afDb: AngularFireDatabase,
    // private userService: UserService,
    private router: Router) {
    // Retrieve user data if logged in
    this.user = this.afAuth.authState
      .switchMap(user => {
        if (user) {
          this.authRef = firebase.auth()
          this.userDbRef = firebase.database()
          console.log('auth services loaded')
          return this.afDb.list('users').valueChanges();
        }
        else {
          return Observable.of(null);
        }
      });
  }

  login(email: string, password: string) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  signup(email, password) {
    return firebase.auth().createUserAndRetrieveDataWithEmailAndPassword(email, password);
  }

  createUser(user) {
    let createUserFunction = firebase.functions().httpsCallable('createUser')
    return createUserFunction(user)
  }

  isLoggedIn() {
    return new Promise(resolve => {
      firebase.auth().onAuthStateChanged(user => {
        user ? resolve(true) : resolve(false)
      })

    })
    // Returns true is logged in
    // return this.afAuth.authState !== null;
  }

  logout() {
    this.afAuth.auth.signOut().then(data => {
      this.router.navigate(['login']);
    });
  }

  getCurrentUserToken() {
    return firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
  }

  changePassword(email, newPassword) {


    return new Promise((resolve, reject) => {
      let regex = new RegExp("^[a-zA-Z0-9]{8,}$");
      let test = regex.test(newPassword)
      if (!test) {
        return reject("Invalid new password. Password must be alphanumeric with 8 digits.")
      }

      if (email !== firebase.auth().currentUser.email) {
        return reject("Invalid email. Provided email doesn't belongs to you.")
      }

      firebase.auth().currentUser.updatePassword(newPassword).then(updatedPassword => {
        this.updateDtLastModifyPassword().then(success=>{
          console.log("successfully updated last modify password date")
          return resolve("Successfully updated password")
        }).catch(error=>{
          console.log("failed to updates last modify password date")
          return resolve("Successfully updated password")
        })
      }).catch(error => {
        return reject(error)
      })

    })
  }

  updateDtLastModifyPassword(){
    return new Promise((resolve,reject)=>{
      this.userDbRef.ref("users").child(firebase.auth().currentUser.uid).update({dtLastModifyPassword:firebase.database.ServerValue.TIMESTAMP}).then(success=>{
        resolve(true)
      }).catch(error=>{
        console.log("updateDtLastModifyPassword error",error)
        reject(error)
      })
    })
  }


}
