import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { HttpService } from '../http/http.service'

import { iwo } from '../../model/iwo.model'
import { reject } from 'q';




@Injectable({
  providedIn: 'root'
})
export class DistributionService {
  currentUser:firebase.User;
  distributionRef:firebase.database.Reference
  
    constructor(
    private httpService: HttpService
  ) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.currentUser = user;
        console.log('Distribution services loaded');
        //console.log(Date.parse("2018-08-08"))
        this.distributionRef = firebase.database().ref(`/distribution`);

      }
      else {
        return false;
      }
    });
  }

  list(){
    return this.distributionRef
  }

  get(id:string){
    return this.distributionRef.child(id).once("value")
  }

  add(distributionRecord){
    delete distributionRecord["id"]
    distributionRecord["dtcreate"] = Date.now()
    delete distributionRecord["checkbox"]
    let obj = {}
    Object.assign(obj,distributionRecord)
    console.log(obj)
    return this.distributionRef.push({...obj})
  }

  update(id:string,distributionRecord){
    distributionRecord["dtmodified"] = Date.now()
    delete distributionRecord["checkbox"]
    return this.distributionRef.child(id).update(distributionRecord)
  }

  delete(id:string){
    return this.distributionRef.child(id).remove()
  }

}
