import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';


// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { CompleteSignupComponent } from './views/complete-signup/complete-signup.component';
import { PendingOrderComponent } from './views/pending-order/pending-order.component';
import { DistributionComponent } from './views/distribution/distribution.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'complete-signup',
    component: CompleteSignupComponent,
    data: {
      title: 'Complete Signup Page'
    }
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'search-results',
        loadChildren: './views/search-results/search-results.module#SearchResultsModule'
      },
      {
        path: 'user-management',
        loadChildren: './views/users/users.module#UsersModule'
      },
      {
        path: 'stock-management',
        loadChildren: './views/stocks/stocks.module#StocksModule'
      },
      {
        path: 'create-iwo',
        loadChildren: './views/iwo-form/iwo-form.module#IwoFormModule'
      },
      {
        path: 'add-client',
        loadChildren: './views/add-client/add-client.module#AddClientModule'
      },
      {
        path: 'orders',
        loadChildren: './views/pending-order/pending-order.module#PendingOrderModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'completed-orders',
        loadChildren: './views/completed-order/completed-order.module#CompletedOrderModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'ex-iwo-orders',
        loadChildren: './views/ex-iwo-order/ex-iwo-order.module#ExIwoOrderModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'activities',
        loadChildren: './views/activities-log/activities-log.module#ActivitiesLogModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'all-clients',
        loadChildren: './views/all-clients/all-clients.module#AllClientsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'change-log',
        loadChildren: './views/change-log/change-log.module#ChangeLogModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'search-results/:clientId/client-details/:iwoId/iwo-details',
        loadChildren: './views/search-results/iwo-details/iwo-details.module#IwoDetailsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'distribution',
        loadChildren: './views/distribution/distribution.module#DistributionModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'account-management',
        loadChildren: './views/account-management/account-management.module#AccountMangementModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'sales-funnel',
        loadChildren: './views/sales-funnel/sales-funnel.module#SalesFunnelModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'sales-funnel/leads-form',
        loadChildren: './views/sales-funnel/leads-form/leads-form.module#LeadsFormModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'sales-funnel/:leadsid/details',
        loadChildren: './views/sales-funnel/leads-details/leads-details.module#LeadsDetailsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'sales-funnel/reports',
        loadChildren: './views/sales-funnel/leads-report/leads-report.module#LeadsReportModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'sales-funnel/target',
        loadChildren: './views/sales-funnel/leads-target/leads-target.module#LeadsTargetModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'sales-funnel/activities',
        loadChildren: './views/sales-funnel/sales-activities/sales-activities.module#SalesActivitiesModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'warehouse',
        loadChildren: './views/warehouse/warehouse.module#WarehouseModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'warehouse/add-stock-form',
        loadChildren: './views/warehouse/add-stock-form/add-stock-form.module#AddStockFormModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'warehouse/loan-management',
        loadChildren: './views/warehouse/loan-management/loan-management.module#LoanManagementModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'warehouse/loan-management/:loanId/details',
        loadChildren: './views/warehouse/loan-details/loan-details.module#LoanDetailsModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'warehouse/:stockId/details',
        loadChildren: './views/warehouse/stock-details/stock-details.module#StockDetailsModule',
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload',useHash: true}) ],
  exports: [ RouterModule ],
  providers: [
    AuthGuard
  ]
})
export class AppRoutingModule {}
