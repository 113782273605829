import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';


@Component({
  templateUrl: '500.component.html'
})
export class P500Component {

  constructor(
    private title:Title
  ) { }

  ngOnInit(){
    this.title.setTitle("Something went wrong")
  }
}
