import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor() { }

  keyword
  version
  searchKeyword(kword: string) {
    if (localStorage.getItem(kword) === null || localStorage.getItem(kword) === undefined) {
      localStorage.setItem(kword, '')
    }

    this.keyword = localStorage.getItem(kword)
    localStorage.removeItem(kword)
  }

  setLocal(key, value) {
    localStorage.setItem(key, value)
  }

  getLocal(key) {
    let value = localStorage.getItem(key)
    return value
  }
  getLocalAndRemove(key) {
    let value = localStorage.getItem(key)
    localStorage.removeItem(key)
    return value
  }

  setCronExecuteDate() {
    try {
      if (this.getCronExecuteDate()) {
        this.removeCronExecuteDate()
      }
      let today = this.generateTodayDate()
      localStorage.setItem("dtcron-90daysNotice", today)
    } catch (error) {
      console.log(error)
    }
    
  }

  getCronExecuteDate() {
    let today = this.generateTodayDate()
    try {
      let value = localStorage.getItem("dtcron-90daysNotice")
      return value
    } catch (error) {
      console.log(error)
    }

  }

  removeCronExecuteDate() {
    try {
      localStorage.removeItem('dtcron-90daysNotice')
    } catch (error) {
      console.log(error)
    }

  }

  setHardRefreshDate(){
    let today = this.getCronExecuteDate()
    try {
      localStorage.setItem('dtHardRefresh',today)
    } catch (error) {
      console.log(error)
    }
  }

  getHardRefreshDate(){
    try {
      return localStorage.getItem('dtHardRefresh')
    } catch (error) {
      console.log(error)
    }
  }

  removeHardRefreshDate(){
    try {
      localStorage.removeItem('dtHardRefresh')
    } catch (error) {
      console.log(error)
    }
  }

  setViewedVersion(){
    try {
      localStorage.setItem('viewedVersion',this.version)
    } catch (error) {
      console.log(error)
    }
  }
  getViewedVersion():string{
    try {
      return localStorage.getItem('viewedVersion')
    } catch (error) {
      console.log(error)
    }
  }

  generateTodayDate() {
    let addZero = function (i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    let timestamp = Date.now()
    let year = new Date(timestamp).getFullYear()
    let month = new Date(timestamp).getMonth() + 1 < 10 ? '0' + (new Date(timestamp).getMonth() + 1) : new Date(timestamp).getMonth() + 1
    let day = new Date(timestamp).getDate() < 10 ? '0' + new Date(timestamp).getDate() : new Date(timestamp).getDate()
    let hour = addZero(new Date(timestamp).getHours());
    let minute = addZero(new Date(timestamp).getMinutes());
    let second = addZero(new Date(timestamp).getSeconds());

    let newDate = year.toString() + '-' + month + '-' + day
    return newDate
  }
}
