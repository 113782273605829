import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';

import * as firebase from 'firebase/app';



@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  currentUser;
  supplierRef: firebase.database.Reference;
  supplierList = []

  constructor(private http: Http) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.currentUser = user;
        this.supplierRef = firebase.database().ref(`/supplier`);
        console.log('supplier services loaded');
        this.getSuppliers().then(data => {
          console.log(data)
          this.monitorLeadsChange()
        })
      }
    });
  }

  monitorLeadsChange() {
    // if (this.currentUser.access.features.salesDashboard.subFeatureOrField.supplierList.read == true) {
    this.supplierRef.on("child_added", snap => {
      let i = this.supplierList.findIndex(e => { return e.key == snap.key })
      if (i < 0) {
        this.supplierList.push({ ...snap.val(), key: snap.key })
        console.log(this.supplierList)
      }
    })
    this.supplierRef.on("child_changed", snap => {
      let i = this.supplierList.findIndex(e => { return e.key == snap.key })
      this.supplierList[i] = { ...snap.val(), key: snap.key }
      console.log(this.supplierList)
    })
    this.supplierRef.on("child_removed", snap => {
      let i = this.supplierList.findIndex(e => { return e.key == snap.key })
      this.supplierList.splice(i, 1)
      console.log(this.supplierList)
    })
    // } else {
    //   this.supplierRef.orderByChild("owner").equalTo(this.currentUser.email).on("child_added", snap => {
    //     let i = this.supplierList.findIndex(e => { return e.key == snap.key })
    //     if (i < 0) {
    //       this.supplierList.push({ ...snap.val(), key: snap.key })
    //       console.log(this.supplierList)
    //     }
    //   })
    //   this.supplierRef.orderByChild("owner").equalTo(this.currentUser.email).on("child_changed", snap => {
    //     let i = this.supplierList.findIndex(e => { return e.key == snap.key })
    //     this.supplierList[i] = { ...snap.val(), key: snap.key }
    //     console.log(this.supplierList)
    //   })
    //   this.supplierRef.orderByChild("owner").equalTo(this.currentUser.email).on("child_removed", snap => {
    //     let i = this.supplierList.findIndex(e => { return e.key == snap.key })
    //     this.supplierList.splice(i, 1)
    //     console.log(this.supplierList)
    //   })

    // }
  }

  checkLoginStatus() {
    return firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.currentUser = user;
        this.supplierRef = firebase.database().ref(`/supplier`);
        console.log('supplier services loaded');
      }
    });
  }

  async getSuppliers() {

    try {
      let suppliers = await this.pagination(0, 50, [])
      this.supplierList = suppliers
      console.log(suppliers)
  
      return suppliers
  
    } catch (error) {
      console.log("[getSuppliers] error", error)
      throw error

    }
  }


  async pagination(key, num, data: any[]) {
    try {
      let snap = await this.supplierRef.orderByChild("dtcreate").startAt(key).limitToFirst(num).once('value')
      console.log(snap.val())
      if (snap.val()) {
        let lastKey = ""
        snap.forEach(e => {
          data.push({ ...e.val(), key: e.key })
          lastKey = e.val()["dtcreate"] + 1
        })
        console.log(data)
        if (snap.numChildren() == num) {
          console.log("looping")
          return await this.pagination(lastKey, num, data)
        } else {
          return data
        }
      }
      else {
        return data
      }
    } catch (error) {
      console.log("[supplier ]", "pagination: Error", error)
      throw error
    }

  }

  getSupplierById(supplierId) {
    return this.supplierRef.child(supplierId);
  }

  searchSupplierByName(name) {
    return this.supplierRef.orderByChild(name);
  }

  createSupplier(supplier) {
    supplier['dtcreate'] = Date.now();
    supplier['dtmodify'] = Date.now();
    delete supplier['isNew'];
    return this.supplierRef.push(supplier);
  }

  updateSupplier(supplier, supplierId) {
    supplier['dtmodify'] = Date.now();
    return this.supplierRef.child(supplierId).update(supplier);
  }

}
