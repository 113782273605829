import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { HttpService } from '../http/http.service'

import { iwo } from '../../model/iwo.model'
import { reject } from 'q';




@Injectable({
  providedIn: 'root'
})
export class ScrollHistoryService {
  currentUser;

  list = {
    iwoList: {
      scrollTop: 0,
      pageLength: 10,
      isFirstScroll:false
    }
  }
  constructor(
    private httpService: HttpService
  ) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.currentUser = user;
        console.log('Scroll history services loaded');
        //console.log(Date.parse("2018-08-08"))
      }
      else {
        return false;
      }
    });
  }

  init(name) {
    if (this.list[name]) {
      this.list[name] = this.getIwoListScrollHistory(name)
      let that = this
      $(window).on("scroll", function (e) {
        console.log(that.list[name])
        let scrollPosition = $(document).scrollTop()
        if(that.list[name].isFirstScroll){
          that.set(name, { scrollTop: scrollPosition })
        }else{
          that.list[name].isFirstScroll = true
        }
      })
      return this.list[name]
    }
  }

  scrollToPosition(position) {
    return $(window).scrollTop(position)
  }

  get(name) {
    return this.list[name]
  }

  set(name, val: { scrollTop?: number, pageLength?: number }) {
    Object.assign(this.list[name], val)
    console.log(this.list[name])
    try {
      window.localStorage[name + "ScrollHistory"] = JSON.stringify(this.list[name])
    } catch (error) {
      console.log(error)
    }
  }

  off(name) {
    this.list[name].isFirstScroll = false
    return $(window).off("scroll")
  }

  getIwoListScrollHistory(name): { scrollTop: number, pageLength?: number } {
    if (this.list[name]) {
      try {
        this.list[name] = JSON.parse(window.localStorage[name + "ScrollHistory"]) || this.list[name]
        return this.list[name]
      } catch (error) {
        console.log(error)
        return this.list[name]
      }
    } else {
      return { scrollTop: 0 }
    }

  }

}
