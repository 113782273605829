import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: '404.component.html'
})
export class P404Component {

  constructor(
    private title:Title
  ) { }

  ngOnInit(){
    this.title.setTitle("Page Not Found")
  }

}
