import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { HttpService } from '../http/http.service'
import { UserService } from '../user/user.service'

import { iwo } from '../../model/iwo.model'
import { reject } from 'q';




@Injectable({
  providedIn: 'root'
})
export class SalesActivitiesService {
  currentUser;
  salesActivitiesRef: firebase.database.Reference
  allActivities = []
  constructor(
    private httpService: HttpService,
    private userService: UserService,
  ) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.userService.getCurrentUser().then(user => {
          this.currentUser = user
          console.log('Sales Activities services loaded', this.currentUser);
          // console.log('Distribution services loaded');
          //console.log(Date.parse("2018-08-08"))
          this.salesActivitiesRef = firebase.database().ref(`/sales-activities`);
          let now = Date.now()
          let dtstart = new Date(new Date(now).getFullYear(), new Date(now).getMonth(), 1, 0, 0, 0).getTime()
          this.getallActivities(dtstart,Date.now()).then(data => {
            this.monitorLeadsChange()
          })

        })
      }
      else {
        return false;
      }
    });
  }

  async getallActivities(dtstart,dtto) {
    try {

      console.log("[getallActivities]", "getting all activities", this.currentUser.access.features.salesDashboard.subFeatureOrField.allActivities.read)
      if (this.currentUser.access.features.salesDashboard.subFeatureOrField.allActivities.read == true) {
        let now = Date.now()
        let dtstartFrom = dtstart || new Date(new Date(now).getFullYear(), new Date(now).getMonth(), 1, 0, 0, 0).getTime()
        let dtTo = dtto || new Date(new Date(now).getFullYear(), new Date(now).getMonth()+1, 0, 0, 0, 0).getTime()
        let activities = await this.pagination(dtstartFrom,dtTo, 10, [])
        this.allActivities = activities
        console.log(activities)

        return activities

      } else {
        let activities = await this.getActivitiesByUid(this.currentUser.uid)
        this.allActivities = activities
        console.log(activities)

        return activities

      }
    } catch (error) {
      console.log("[getallActivities] error", error)
      throw error
    }
  }


  async pagination(key, dtto, num, data: any[]) {
    try {
      let snap = await this.salesActivitiesRef.orderByChild("dtcreate").startAt(key).limitToFirst(num).once('value')
      console.log(snap.val())
      if (snap.val()) {
        let lastKey = ""
        let isStop = false
        snap.forEach(e => {
          let obj = { ...e.val(), id: e.key }
          if(obj["dtcreate"]>dtto){
            isStop = true
          }else{
            data.push(obj)
            lastKey = e.val()["dtcreate"] + 1
          }
        })
        console.log(data)
        if(isStop){
          return data
        }
        if (snap.numChildren() == num) {
          console.log("looping")
          return await this.pagination(lastKey,dtto, num, data)
        } else {
          return data
        }
      }
      else {
        return data
      }
    } catch (error) {
      console.log("[activities]", "pagination: Error", error)
      throw error
    }

  }

  async getActivitiesByUid(uid) {
    try {
      let data = []
      let snap = await this.salesActivitiesRef.orderByChild("uid").equalTo(uid).once('value')
      console.log(snap.val())
      if (snap.val()) {
        snap.forEach(e => {
          data.push({ ...e.val(), id: e.key })
        })
        console.log(data)
        return data
      }
      else {
        return data
      }
    } catch (error) {
      console.log("[activities]", "pagination: Error", error)
      throw error
    }

  }

  monitorLeadsChange() {
    console.log("monitorLeadsChange")
    if (this.currentUser.access.features.salesDashboard.subFeatureOrField.allActivities.read == true) {
      this.salesActivitiesRef.on("child_added", snap => {
        let i = this.allActivities.findIndex(e => { return e.id == snap.key })
        if (i < 0) {
          this.allActivities.push({ ...snap.val(), id: snap.key })
          console.log(this.allActivities)
        }
      })
      this.salesActivitiesRef.on("child_changed", snap => {
        let i = this.allActivities.findIndex(e => { return e.id == snap.key })
        this.allActivities[i] = { ...snap.val(), id: snap.key }
        console.log(this.allActivities)
      })
      this.salesActivitiesRef.on("child_removed", snap => {
        let i = this.allActivities.findIndex(e => { return e.id == snap.key })
        this.allActivities.splice(i, 1)
        console.log(this.allActivities)
      })
    } else {
      this.salesActivitiesRef.orderByChild("owner").equalTo(this.currentUser.email).on("child_added", snap => {
        let i = this.allActivities.findIndex(e => { return e.id == snap.key })
        if (i < 0) {
          this.allActivities.push({ ...snap.val(), id: snap.key })
          console.log(this.allActivities)
        }
      })
      this.salesActivitiesRef.orderByChild("owner").equalTo(this.currentUser.email).on("child_changed", snap => {
        let i = this.allActivities.findIndex(e => { return e.key == snap.key })
        this.allActivities[i] = { ...snap.val(), id: snap.key }
        console.log(this.allActivities)
      })
      this.salesActivitiesRef.orderByChild("owner").equalTo(this.currentUser.email).on("child_removed", snap => {
        let i = this.allActivities.findIndex(e => { return e.key == snap.key })
        this.allActivities.splice(i, 1)
        console.log(this.allActivities)
      })

    }
  }

  list(status) {
    if (status) {
      return this.salesActivitiesRef.orderByChild("status").equalTo(status)
    } else {
      return this.salesActivitiesRef
    }
  }

  get(id: string) {
    return this.salesActivitiesRef.child(id).once("value")
  }

  add(distributionRecord) {
    delete distributionRecord["id"]
    distributionRecord["dtcreate"] = Date.now()
    delete distributionRecord["checkbox"]
    let obj = {}
    Object.assign(obj, distributionRecord)
    console.log(obj)

    return this.salesActivitiesRef.push({ ...obj })
  }

  update(id: string, distributionRecord) {
    distributionRecord["dtmodified"] = Date.now()
    delete distributionRecord["checkbox"]
    return this.salesActivitiesRef.child(id).update(distributionRecord)
  }

  delete(id: string) {
    return this.salesActivitiesRef.child(id).remove()
  }

}
