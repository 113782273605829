import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { HttpService } from '../http/http.service'
import { UserService } from '../user/user.service'

import { iwo } from '../../model/iwo.model'
import { reject } from 'q';
import { UtilService } from '../util/util.service';




@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
  currentUser;
  warehouseRef: firebase.database.Reference
  counterRef: firebase.database.Reference
  loanRecordRef: firebase.database.Reference

  // targetRef: firebase.database.Reference
  allStocks = []
  allStocksReady = false
  allLoanRecords = []
  allLoanRecordsReady = false

  // counter = {
  //   GL: {
  //     "1123":0
  //   },
  //   GR: {
  //     "1223":0
  //   },
  //   RD: {
  //     "1223":0
  //   },
  //   RG: {
  //     "1223":0
  //   },
  //   RI: {
  //     "1223":0
  //   },
  //   RO: {
  //     "1223":0
  //   }
  // }

  statusMap = {
    "active": "Active",
    "inactive": "Inactive",
    "onloan": "Goods On Loan",
    "onloanReturn": "Goods On Loan Return",
    "rma-grn": "RMA-GRN",
    "rma-outward": "RMA-Outwards",
    "rma-inward": "RMA-Inwards",
    "rma-deliveryOrder": "RMA-Delivery Order",
    "rma-warranty": "RMA Warranty",
    "rma-non-warranty": "RMA Non-Warranty",
    "rma-return": "RMA Return",
    "rma-do": "RMA DO",
    "rma-disposed": "RMA Disposed",
    "disposal": "Disposal",
    "doa": "DOA",
    "doa-outward": "DOA Outward",
    "doa-inward": "DOA Inward",
    "doa-return": "DOA Return",
  }

  constructor(
    private httpService: HttpService,
    private userService: UserService,
    private utilService: UtilService,
  ) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.userService.getCurrentUser().then(user => {
          this.currentUser = user
          console.log('Leads services loaded', this.currentUser);
          //console.log(Date.parse("2018-08-08"))
          this.warehouseRef = firebase.database().ref(`/warehouse`);
          this.counterRef = firebase.database().ref(`/counter`);
          this.loanRecordRef = firebase.database().ref(`/loanRecord`);
          // this.targetRef = firebase.database().ref(`/targets`);
          this.getAllStocks().then(data => {
            this.allStocksReady = true
            this.monitorLeadsChange()
          })

          this.listLoanRecord().on("value", snap => {
            snap.forEach(val => {
              let obj = val.val()
              obj["key"] = val.key
              obj["id"] = val.key
              let index = this.allLoanRecords.findIndex((e) => {
                return e["key"] == val.key
              })
              if (index == -1) {
                this.allLoanRecords.push(obj)
              } else {
                this.allLoanRecords[index] = obj
              }
            })
            this.allLoanRecordsReady = true
          })
          // this.getCounter()

        })
      }
      else {
        return false;
      }

      console.log("MMYY - ", this.utilService.mmYY(Date.now()))
    });
  }

  async getAllStocks() {
    try {

      console.log("[getAllStocks]", "getting all stock", this.currentUser.access.features.salesDashboard.subFeatureOrField.allLeads.read)
      // if (this.currentUser.access.features.salesDashboard.subFeatureOrField.allLeads.read == true) {
      let stocks = await this.pagination(0, 50, [])
      this.allStocks = stocks
      console.log(stocks)

      return stocks

      // } else {
      //   let leads = await this.getLeadsByEmail(this.currentUser.email)
      //   this.allStocks = leads
      //   console.log(leads)

      //   return leads

      // }
    } catch (error) {
      console.log("[getAllStocks] error", error)
      throw error
    }
  }



  async pagination(key, num, data: any[]) {
    try {
      let snap = await this.warehouseRef.orderByChild("dtcreate").startAt(key).limitToFirst(num).once('value')
      console.log(snap.val())
      if (snap.val()) {
        let lastKey = ""
        snap.forEach(e => {
          data.push({ ...e.val(), key: e.key })
          lastKey = e.val()["dtcreate"] + 1
        })
        console.log(data)
        if (snap.numChildren() == num) {
          console.log("looping")
          return await this.pagination(lastKey, num, data)
        } else {
          return data
        }
      }
      else {
        return data
      }
    } catch (error) {
      console.log("[leads ]", "pagination: Error", error)
      throw error
    }

  }

  async getLeadsByEmail(email) {
    try {
      let data = []
      let snap = await this.warehouseRef.orderByChild("owner").equalTo(email).once('value')
      console.log(snap.val())
      if (snap.val()) {
        snap.forEach(e => {
          data.push({ ...e.val(), key: e.key })
        })
        console.log(data)
        return data
      }
      else {
        return data
      }
    } catch (error) {
      console.log("[leads ]", "pagination: Error", error)
      throw error
    }

  }

  monitorLeadsChange() {
    // if (this.currentUser.access.features.salesDashboard.subFeatureOrField.allLeads.read == true) {
    this.warehouseRef.on("child_added", snap => {
      let i = this.allStocks.findIndex(e => { return e.key == snap.key })
      if (i < 0) {
        this.allStocks.push({ ...snap.val(), key: snap.key })
        console.log(this.allStocks)
      }
    })
    this.warehouseRef.on("child_changed", snap => {
      let i = this.allStocks.findIndex(e => { return e.key == snap.key })
      this.allStocks[i] = { ...snap.val(), key: snap.key }
      console.log(this.allStocks)
    })
    this.warehouseRef.on("child_removed", snap => {
      let i = this.allStocks.findIndex(e => { return e.key == snap.key })
      this.allStocks.splice(i, 1)
      console.log(this.allStocks)
    })
    // } else {
    //   this.warehouseRef.orderByChild("owner").equalTo(this.currentUser.email).on("child_added", snap => {
    //     let i = this.allStocks.findIndex(e => { return e.key == snap.key })
    //     if (i < 0) {
    //       this.allStocks.push({ ...snap.val(), key: snap.key })
    //       console.log(this.allStocks)
    //     }
    //   })
    //   this.warehouseRef.orderByChild("owner").equalTo(this.currentUser.email).on("child_changed", snap => {
    //     let i = this.allStocks.findIndex(e => { return e.key == snap.key })
    //     this.allStocks[i] = { ...snap.val(), key: snap.key }
    //     console.log(this.allStocks)
    //   })
    //   this.warehouseRef.orderByChild("owner").equalTo(this.currentUser.email).on("child_removed", snap => {
    //     let i = this.allStocks.findIndex(e => { return e.key == snap.key })
    //     this.allStocks.splice(i, 1)
    //     console.log(this.allStocks)
    //   })

    // }
  }

  list(status) {
    if (status) {
      return this.warehouseRef.orderByChild("status").equalTo(status)
    } else {
      return this.warehouseRef
    }
  }

  get(id: string) {
    return this.warehouseRef.child(id).once("value")
  }

  add(stockRecord) {
    return new Promise(resolve => {
      let timestamp = Date.now();
      stockRecord['dtmodify'] = timestamp;
      stockRecord['status'] = 'active'

      delete stockRecord["id"]
      stockRecord["dtcreate"] = firebase.database.ServerValue.TIMESTAMP
      let obj = {}
      Object.assign(obj, stockRecord)
      console.log(obj)
      this.warehouseRef.push({ ...obj }).then(inserted => {
        resolve(inserted)
      })
    }).catch(error => {
      console.log("add stock error", error)
      reject(error)

    })
  }

  update(id: string, stockRecord) {
    stockRecord["dtmodified"] = firebase.database.ServerValue.TIMESTAMP
    delete stockRecord["checkbox"]
    return this.warehouseRef.child(id).update(stockRecord)
  }

  updateCounter(type, value) {
    return this.counterRef.child("warehouse").child(type).update(value)
  }


  delete(id: string) {
    return this.warehouseRef.child(id).remove()
  }

  generateLoanRecordId() {
    return this.loanRecordRef.push().key
  }

  addLoanRecord(loanRecord) {
    return new Promise(resolve => {
      let timestamp = Date.now();
      loanRecord['dtmodify'] = firebase.database.ServerValue.TIMESTAMP;
      loanRecord['status'] = 'active'

      delete loanRecord["id"]
      loanRecord["dtcreate"] = firebase.database.ServerValue.TIMESTAMP
      let obj = {}
      Object.assign(obj, loanRecord)
      console.log(obj)
      this.loanRecordRef.push({ ...obj }).then(inserted => {
        resolve(inserted)
      })
    }).catch(error => {
      console.log("addLoanRecord error", error)
      reject(error)

    })
  }

  updateLoanRecord(id: string, loanRecord) {
    loanRecord["dtmodified"] = firebase.database.ServerValue.TIMESTAMP
    delete loanRecord["checkbox"]
    return this.loanRecordRef.child(id).update(loanRecord)
  }

  listLoanRecord(status?) {
    if (status) {
      return this.loanRecordRef.orderByChild("status").equalTo(status)
    } else {
      return this.loanRecordRef
    }
  }

  getLoanRecord(id: string) {
    return this.loanRecordRef.child(id).once("value")
  }


  getStockStatusFromHistories(histories): { status: string, statusDesc: string, record: any } {
    let result = {
      status: "active",
      statusDesc: "",
      record: {}
    }
    histories.forEach(record => {
      console.log("record", record)
      result.record = JSON.parse(JSON.stringify(record))
      if (record.action == "onLoanReturn" || record.action == "cancelLoanToCustomer") {
        result.status = 'active'
        result.statusDesc = "onLoanReturn"


      } else if (record.action == "loanToCustomer" || record.action == "updateloanToCustomer") {
        result.status = 'onloan'
        result.statusDesc = "onloan"

      } else if (record.action == "ReturnFromCustomer") {
        result.status = 'rma-grn'
        result.statusDesc = "rma-grn"

      } else if (record.action == "rma-outward-warranty") {
        result.status = "rma-outward"
        result.statusDesc = "rma-warranty"

      } else if (record.action == "rma-outward-non-warranty") {
        result.status = "rma-outwrd"
        result.statusDesc = "rma-non-warranty"

      } else if (record.action == "rma-inward") {
        // this.loanToStatus = "available"
        result.status = "rma-inward"
        result.statusDesc = "rma-inward"
      } else if (record.action == "rma-return") {
        // this.loanToStatus = "available"
        result.status = "rma-return"
        result.statusDesc = "rma-return"
      } else if (record.action == "rma-do") {
        // this.loanToStatus = "available"
        result.status = "rma-do"
        result.statusDesc = "rma-do"
      }
    })

    return result
  }



  generateReferenceNumber(name, projectType) {
    if (!name) {
      throw new Error("name is missing")
    }

    if (!projectType) {
      throw new Error("project type is missing")
    }

    name = name.match(/\w/gm).join("").slice(0, 5).toUpperCase()
    if (projectType == 'project') {
      projectType = "Q"
    } else if (projectType == 'tender') {
      projectType = "Q"
    } else {
      projectType = "Q"
    }
    let index = 1
    let refNum //refNum format = [companyname]/[Q or P]-[runningNumber]/[month][year]
    let timestamp = Date.now()
    let year = new Date(timestamp).getFullYear()
    let month = new Date(timestamp).getMonth() + 1 < 10 ? '0' + (new Date(timestamp).getMonth() + 1) : new Date(timestamp).getMonth() + 1
    let day = new Date(timestamp).getDate() < 10 ? '0' + new Date(timestamp).getDate() : new Date(timestamp).getDate()
    //console.log(timestamp, year, month, day)
    return new Promise(resolve => {
      this.warehouseRef.orderByChild('indexYear').equalTo(year).limitToLast(1).once('value', snap => {
        console.log(snap.numChildren(), snap.val())
        if (snap.val() === null || snap.val() === undefined) {
          let newYear = year - 2000
          refNum = name + '/' + projectType + '-000' + index + '/' + month + newYear
          console.log(refNum)
          let object = { refNum: refNum, index: index }
          resolve(object)
        }
        else {
          let tempArray = []
          snap.forEach(element => {
            tempArray.push(element.val().index)
          });
          tempArray = tempArray.filter(tempArrayElement => tempArrayElement !== undefined)
          //console.log(tempArray)
          //console.log(Math.max(...tempArray))
          index = Math.max(...tempArray)
          index = index + 1
          let newYear = year - 2000
          if (index < 10) {
            refNum = name + '/' + projectType + '-000' + index + '/' + month + newYear
          }
          else if (index < 100 && index >= 10) {
            refNum = name + '/' + projectType + '-00' + index + '/' + month + newYear
          }
          else if (index < 1000 && index >= 100) {
            refNum = name + '/' + projectType + '-0' + index + '/' + month + newYear
          }
          else {
            refNum = name + '/' + projectType + '-' + index + '/' + month + newYear
          }
          //console.log(refNum)
          let object = { refNum: refNum, index: index }
          console.log(object)
          resolve(object)
        }
      })
    })



  }


  async waitForStockList() {
    await this.wait(100)
    console.log("Wait For Stock List", this.allStocks.length)

    if (this.allStocksReady == true) {
      return true
    } else {
      return await this.waitForStockList()

    }

  }

  async waitForLoanList() {
    await this.wait(100)
    console.log("Wait For Loan List", this.allLoanRecords.length)

    if (this.allLoanRecordsReady == true) {
      return true
    } else {
      return await this.waitForLoanList()

    }

  }


  wait(ms) {
    return new Promise(resolve => { setTimeout(resolve, ms); })
  }
}
