import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { HttpService } from '../http/http.service'
import { UserService } from '../user/user.service'

import { iwo } from '../../model/iwo.model'
import { reject } from 'q';




@Injectable({
  providedIn: 'root'
})
export class OrderService {
  currentUser: firebase.User;
  iwoRef: firebase.database.Reference;
  exIwoRef: firebase.database.Reference;
  auditFormRef: firebase.database.Reference;
  orderList = []

  constructor(
    private httpService: HttpService,
    private userService: UserService
  ) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.currentUser = user;
        this.iwoRef = firebase.database().ref(`/iwo`);
        this.exIwoRef = firebase.database().ref(`/exIwo`)
        this.auditFormRef = firebase.database().ref(`/auditForm`)
        console.log('order services loaded');
        //console.log(Date.parse("2018-08-08"))
        this.getIwoList().once("value", snap => {
          snap.forEach(element => {
            let object = element.val();
            this.orderList.push(object)
          });
        })
      }
      else {
        return false;
      }
    });
  }

  createIwo(iwo) {
    return new Promise(resolve => {
      this.generateIWONumber().then(data => {
        let timestamp = Date.now();
        iwo['iwoNum'] = data['iwoNum']
        iwo['index'] = data['index']
        iwo['indexYear'] = new Date(timestamp).getFullYear();
        iwo['dtcreate'] = timestamp;
        iwo['dtmodify'] = timestamp;
        iwo['status'] = 'pending'

        this.iwoRef.push(iwo).then(data2 => {
          //let sendEmailFunction = firebase.functions().httpsCallable('sendEmail')
          let emailList = [
            {
              recipient: "iwo_alert@excelcomms.com.my",
              subject: "IWO Created: " + iwo.iwoNum,
              text: "This is to inform you that an IWO has been created by " + this.currentUser.email,
              html: "This is to inform you that an IWO has been created by <b>" + this.currentUser.email + "</b>"
            }
          ]



          if (this.currentUser.email !== "iwo_alert@excelcomms.com.my") {
            emailList.push(
              {
                recipient: this.currentUser.email,
                subject: "IWO Created: " + iwo.iwoNum,
                text: "This is to inform you that an IWO has been created by " + this.currentUser.email,
                html: "This is to inform you that an IWO has been created by <b>" + this.currentUser.email + "</b>"
              }
            )
          }
          emailList.forEach(element => {
            this.sendEmail(element)
          })

          this.auditFormRef.push({ iwoId: data2['key'] })

          resolve(data2);

        })
      })
    })
  }

  async sendEmail(email) {
    try {
      const data = await this.httpService.sendEmail(email);
      console.log(data)
    }
    catch (error) {
      console.log(error)
    }
  }

  middleMan() {
    this.generateIWONumber().then(data => {
      //console.log(data)
    })
  }


  generateIWONumber() {
    let index = 1
    let iwoNum //iwoNum format = iwoMMDD-runningNumber
    let timestamp = Date.now()
    let year = new Date(timestamp).getFullYear()
    let month = new Date(timestamp).getMonth() + 1 < 10 ? '0' + (new Date(timestamp).getMonth() + 1) : new Date(timestamp).getMonth() + 1
    let day = new Date(timestamp).getDate() < 10 ? '0' + new Date(timestamp).getDate() : new Date(timestamp).getDate()
    //console.log(timestamp, year, month, day)
    return new Promise(resolve => {
      this.iwoRef.orderByChild('indexYear').equalTo(year).limitToLast(1).once('value', snap => {
        // console.log(snap.val())
        if (snap.val() === null || snap.val() === undefined) {
          let newYear = year - 2000
          iwoNum = 'iwo' + month + newYear + '-000' + index
          console.log(iwoNum)
          let object = { iwoNum: iwoNum, index: index }
          resolve(object)
        }
        else {
          //console.log(snap.val())
          let tempArray = []
          snap.forEach(element => {
            tempArray.push(element.val().index)
          });
          tempArray = tempArray.filter(tempArrayElement => tempArrayElement !== undefined)
          //console.log(tempArray)
          //console.log(Math.max(...tempArray))
          index = Math.max(...tempArray)
          index = index + 1
          let newYear = year - 2000
          if (index < 10) {
            iwoNum = 'iwo' + month + newYear + '-000' + index
          }
          else if (index < 100 && index >= 10) {
            iwoNum = 'iwo' + month + newYear + '-00' + index
          }
          else if (index < 1000 && index >= 100) {
            iwoNum = 'iwo' + month + newYear + '-0' + index
          }
          else {
            iwoNum = 'iwo' + month + newYear + '-' + index
          }
          //console.log(iwoNum)
          let object = { iwoNum: iwoNum, index: index }
          console.log(object)
          resolve(object)
        }
      })
    })
  }


  createExIwo(iwo) {
    return new Promise(resolve => {
      this.generateExIwoNum().then(data => {
        let timestamp = Date.now();
        iwo['iwoNum'] = data['iwoNum']
        iwo['exIndex'] = data['exIndex']
        iwo['indexYear'] = new Date(timestamp).getFullYear();
        iwo['dtcreate'] = timestamp;
        iwo['dtmodify'] = timestamp;
        iwo['status'] = 'exIwo'
        iwo['isNew'] = 'ex'

        this.iwoRef.push(iwo).then(data2 => {
          //let sendEmailFunction = this.httpService.sendEmail
          let emailList = [
            {
              recipient: "iwo_alert@excelcomms.com.my",
              subject: "exIWO Created: " + iwo.iwoNum,
              text: "This is to inform you that an ex IWO has been created by " + this.currentUser.email,
              html: "This is to inform you that an ex IWO has been created by <b>" + this.currentUser.email + "</b>"
            }
          ]



          if (this.currentUser.email !== "iwo_alert@excelcomms.com.my") {
            emailList.push(
              {
                recipient: this.currentUser.email,
                subject: "exIWO Created: " + iwo.iwoNum,
                text: "This is to inform you that an ex IWO has been created by " + this.currentUser.email,
                html: "This is to inform you that an ex IWO has been created by <b>" + this.currentUser.email + "</b>"
              }
            )
          }
          emailList.forEach(element => {
            this.sendEmail(element)
          })


          return resolve(data2);

        },
          error => {
            return reject(error)
          })
      })
    })
  }

  generateExIwoNum() {
    let exIndex = 1
    let iwoNum
    return new Promise(resolve => {
      this.iwoRef.orderByChild('exIndex').limitToLast(1).once('value', snap => {
        if (snap.val() !== null || snap.val() !== undefined) {
          //console.log(snap.val())
          snap.forEach(element => {
            console.log(element.val())
            if (element.val().exIndex === null || element.val().exIndex === undefined) {
              console.log("no ex iwo num found")
              iwoNum = 'iwo-ex-000' + exIndex
              let object = { iwoNum: iwoNum, exIndex: exIndex }
              resolve(object)
            }
            else {
              console.log("ex iwo num found")
              exIndex = element.val().exIndex + 1
              if (exIndex < 10) {
                iwoNum = 'iwo-ex' + '-000' + exIndex
              }
              else if (exIndex < 100 && exIndex >= 10) {
                iwoNum = 'iwo-ex' + '-00' + exIndex
              }
              else if (exIndex < 1000 && exIndex >= 100) {
                iwoNum = 'iwo-ex' + '-0' + exIndex
              }
              else {
                iwoNum = 'iwo-ex' + '-' + exIndex
              }
              //iwoNum = 'iwo-ex-' + exIndex
              let object = { iwoNum: iwoNum, exIndex: exIndex }
              resolve(object)
            }
          });
        } else {
          console.log("no ex iwo num found")
          iwoNum = 'iwo-ex-000' + exIndex
          let object = { iwoNum: iwoNum, exIndex: exIndex }
          resolve(object)
        }
      })
    })
  }

  updateIwo(iwo, iwoId) {

    // let sendEmailFunction = firebase.functions().httpsCallable('sendEmail')
    //       let email = {
    //         recipient: this.currentUser.email,
    //         subject: "IWO Updated: " + iwo.iwoNum,
    //         text: "This is to inform you that a new IWO has been updated by " + this.currentUser.email,
    //         html: "This is to inform you that a new IWO has been updated by <b>" + this.currentUser.email + "</b>"
    //       }
    //       sendEmailFunction(email).then(data3 => {
    //         console.log(data3)
    //       }, error => {
    //         console.log(error)
    //       })

    iwo['dtmodify'] = Date.now();
    return this.iwoRef.child(iwoId).update(iwo)


  }

  deleteIwo(iwo, iwoId) {
    iwo['dtmodify'] = Date.now();
    iwo['dtdelete'] = Date.now()
    iwo['status'] = 'deleted'
    return this.iwoRef.child(iwoId).update(iwo)
  }

  getAuditForm(iwoId) {
    return this.auditFormRef.orderByChild("iwoId").equalTo(iwoId)
  }


  signAuditFormScope(iwoId, scope, data) {
    console.log("signAuditFormScope", iwoId, scope, data)
    return new Promise((resolve, reject) => {
      this.auditFormRef.orderByChild("iwoId").equalTo(iwoId).once("value", async snap => {
        try {
          let arr = []

          snap.forEach(e => {
            arr.push({ id: e.key, ...e.val() })
          })


          let auditForm = arr[0]
          let auditFormId = ""
          if (!auditForm) {
            auditForm = { iwoId }
            auditFormId = this.auditFormRef.push().key
            await this.auditFormRef.child(auditFormId).update(auditForm)
          } else {
            auditFormId = auditForm.id
          }
          console.log(auditFormId, auditForm)
          if (auditForm[scope] && auditForm[scope].signature && auditForm[scope].signature.dtsign && auditForm[scope].signature.signerEmail && auditForm[scope].signature.signerIP && auditForm[scope].signature.signerDevice && auditForm[scope].signature.signerUid) {
            return reject(`this scope has been signed on ${new Date(auditForm[scope].signature.dtsign)}`)
          } else {
            let deviceInfo = await this.userService.getUserDeviceInfo()
            console.log(deviceInfo)
            auditForm[scope] = {
              ...data,
              signature: {
                dtsign: Date.now(),
                signerEmail: this.currentUser.email,
                signerIP: deviceInfo.ip,
                signerDevice: deviceInfo.uag,
                signerUid: this.currentUser.uid
              }
            }
            let signed = await this.auditFormRef.child(auditFormId).child(scope).update(auditForm[scope])
            return resolve(signed)
          }
        } catch (error) {
          console.log(error)
          return reject(error)

        }
      })

    })
  }


  restoreIwo(iwo, iwoId) {
    iwo['dtmodify'] = Date.now();
    iwo['dtrestore'] = Date.now()
    iwo['status'] = 'pending'
    return this.iwoRef.child(iwoId).update(iwo)
  }

  getIwoList() {
    return this.iwoRef
  }


  getIwoListByClientId(clientId) {
    return this.iwoRef.orderByChild('clientId').equalTo(clientId)
  }

  getIwoById(iwoId) {
    this.middleMan()
    return this.iwoRef.child(iwoId)
  }

  completeOrder(iwoId) {
    return this.iwoRef.child(iwoId).update({ status: 'completed' })
  }

  markAsPending(iwoId) {
    return this.iwoRef.child(iwoId).update({ status: 'pending' })
  }

  updatingIwo(iwoId) {
    return this.iwoRef.child(iwoId).update({ updating: { status: 'updating', email: this.currentUser.email, dtmodify: Date.now() } })
  }

  updatedIwo(iwoId) {
    return this.iwoRef.child(iwoId).update({ updating: { status: 'updated', email: '' } })

  }

  getPendingOrder() {
    return this.iwoRef.orderByChild('status').equalTo('pending')
  }

  getOrderByStaus(status) {
    return this.iwoRef.orderByChild('status').equalTo(status)
  }

  getOrderEquipment() {
    return this.iwoRef.orderByChild('equipment')
  }

  setCronProperty(key) {
    return this.iwoRef.child(key).update({ cronJob: { notice90Days: false } })
  }


}
